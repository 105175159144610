// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import logo from "../images/the-new-toys-logo.png";
import { FaBars } from "react-icons/fa";

const Header = ({ siteTitle }) => {
    return (
        <header>
            <div className="header__logo-and-nav">
                <div className="header__logo">
                    <Link className="link--subtle"to="/#">
                        {/*TJC-DEBUG Fix image*/}
                        <img className="header__logo-img" src={logo} role="presentation" />
                    </Link>
                    <Link className="logo-text link--subtle" tabIndex="-1" to="/#">
                        {siteTitle}
                    </Link>
                </div>
                <nav className="top-nav">
                    <ul>
                        <li><Link to="/#">Home</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/bookcode">Code Downloads</Link></li>
                        <li><Link to="/coming-soon">Coming Soon</Link></li>
                        <li className="top-nav__hamburger-icon">
                            <FaBars tabIndex="tabIndex" />
                            <div className="top-nav__hamburger-menu">
                                <ul>
                                    <li><Link to="/#">Home</Link></li>
                                    <li><Link to="/toc">Table of Contents</Link></li>
                                    <li><Link to="/bookcode">Code Downloads</Link></li>
                                    <li><Link to="/coming-soon">Coming Soon</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>     
            <div className="divider--top" />
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
