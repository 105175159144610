// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.

import React from "react";
import PropTypes from "prop-types";

import { useSiteMetadata } from "../hooks/useSiteMetaData";
import Header from "./header";
import Footer from "./footer";
import "./main.css";
import "./blog.css";
import "./toc.css";

const Layout = ({ className, "class": cls = className, children }) => {
    const {title} = useSiteMetadata();

    // Consider a `main` tag wrapper?
    return (
        <>
            <Header siteTitle={title} />
            <main className={cls}>
                {children}
            </main>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
