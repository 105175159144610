// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import { Link } from "gatsby";
import { FaTwitter, FaStackOverflow, FaExternalLinkAlt, FaGrin } from "react-icons/fa";

import logo from "../images/the-new-toys-logo.png";
import { useSiteMetadata } from "../hooks/useSiteMetaData";

const ExtLink = () => 
    <FaExternalLinkAlt style={{ paddingLeft: "4px", fontSize: "smaller", opacity: 0.8 }} />;

export default function Footer() {
    const { copyrightHolder, copyrightFrom } = useSiteMetadata();

    const copyrightTo = String(new Date().getFullYear());
    const copyrightDates = copyrightFrom === copyrightTo
        ? copyrightTo
        : `${copyrightFrom}-${copyrightTo}`;

    return (
        <>
            <div className="divider--bottom" />
            <footer className="footer__logo-and-nav">

                <nav className="footer__flex-container--left">

                    <div className="footer__nav-column">
                        <h6>Buy <FaGrin className="buy-text"/></h6>
                        <ul>
                            <li className="nowrap">
                                <Link to="/where-to-buy">Where to buy</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer__nav-column">
                        <h6>The Book</h6>
                        <ul>
                            <li className="nowrap"><Link to="/toc" className="footer__nav-link">Table of Contents</Link></li>
                            <li className="nowrap">
                                <Link to="/blog/2021/04/10/more-toys/" className="footer__nav-link">More Toys!</Link>
                                <span style={{fontSize: 14}}> &#x2749; </span>
                                <Link to="/blog/2021/03/24/errata/" className="footer__nav-link">Errata</Link>
                            </li>
                            <li className="nowrap">
                                <Link to="/back-cover" className="footer__nav-link">Back Cover</Link>
                                <span style={{fontSize: 14}}> &#x2749; </span>
                                <Link to="/bookcode" className="footer__nav-link">Downloads</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer__nav-column">
                        <h6>The Author</h6>
                        <ul>
                            <li className="nowrap">
                                <a className="footer__nav-link" target="_blank" href="https://twitter.com/tjcrowder">
                                    <FaTwitter />
                                    {" Twitter "}
                                    <ExtLink />
                                </a>
                            </li>
                            <li className="nowrap">
                                <a className="footer__nav-link" target="_blank" href="https://stackoverflow.com/users/157247/t-j-crowder?tab=profile">
                                    <FaStackOverflow />
                                    {" Stack Overflow "}
                                    <ExtLink />
                                </a>
                            </li>
                        </ul>
                    </div>

                </nav>

                <div className="footer__flex-container--right">

                    <div className="footer__logo">
                        <Link to="/#" className="link--no-deco">
                            <img className="footer__logo-img" src={logo} />
                        </Link>
                        <Link className="logo-text link--subtle" tabIndex="-1" to="/#">
                            JavaScript: The New Toys
                        </Link>
                    </div>

                    <div className="footer__sm-print" style={{textAlign: "center"}}>
                        <div>
                            <span className="nowrap">&copy; {copyrightDates} {copyrightHolder}.</span>
                            {" "}
                            <span className="nowrap">All rights reserved.</span>
                        </div>
                        <div className="nowrap">
                            <Link to="/privacy">Privacy &amp; Cookie Policy</Link>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    );
}