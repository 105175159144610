// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../hooks/useSiteMetaData";
import favicon from "../images/the-new-toys-logo.png";

export default function PageInfo({ description, lang, meta, title }) {
    const siteMetadata = useSiteMetadata();

    const metaDescription = description || siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: siteMetadata.authorTwitter,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
            link={[
                {rel: "shortcut icon", type: "image/png", href: favicon}
            ]}
        />
    );
}

PageInfo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

PageInfo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};
