// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import { useStaticQuery, graphql } from "gatsby";

export function useSiteMetadata() {
    const {site: {siteMetadata}} = useStaticQuery(graphql`
        query SiteBasicsQuery {
            site {
              siteMetadata {
                  title
                  copyrightFrom
                  copyrightHolder
              }
          }
        }
    `);
    return siteMetadata;
};